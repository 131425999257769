// `usePageContext` allows us to access `pageContext` in any React component.
// See https://vike.dev/pageContext-anywhere

import { createContext, ReactNode, useContext } from 'react'

import type { PageContext } from './types.ts'

const Context = createContext<PageContext>(undefined as unknown as PageContext)

export const PageContextProvider = ({
  pageContext,
  children,
}: {
  pageContext: PageContext
  children: ReactNode
}) => {
  return <Context.Provider value={pageContext}>{children}</Context.Provider>
}

export function usePageContext() {
  return useContext(Context)
}
